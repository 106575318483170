<template>
  <div class="scroll-page">
    <div class="user-bg">
      <img src="~@/assets/images/user_bg.png" alt="">
    </div>
    <!-- 用户卡片 -->
    <div class="user-header">
      <div class="avatar">
        <img :src="userinfo.headimg" class="fit-img" alt="">
      </div>
      <div class="info">
        <div class="username">
          <div class="title van-ellipsis">{{ userinfo.username }}</div>
          <div class="tag flex-center">{{ level }}</div>
        </div>
        <div class="desc">
          <img src="~@/assets/images/user/icon_vip.svg" class="tag-img" alt="">
          <span>会员积分：{{ score }}</span>
          <div class="score-img">
            <img src="~@/assets/images/reload.svg"  @click="reload" alt="">
          </div>
        </div>
      </div>
      <router-link to="/userinfo" class="more-btn flex-center">
        <span>查看资料</span>
        <van-icon name="arrow" color="#999999" size="3.2vw"></van-icon>
      </router-link>
    </div>

    <!-- 产品 & 地址 -->
    <div class="link-card-box">
      <router-link to="/mypro" class="card link-card">
        <div class="icon">
          <img src="~@/assets/images/user/icon_pro.svg" class="contain-img" alt="">
        </div>
        <div class="info">
          <div class="title">已购产品</div>
          <div class="desc">已购买的电器产品</div>
        </div>
      </router-link>
      <router-link to="/address" class="card link-card">
        <div class="icon">
          <img src="~@/assets/images/user/icon_addr.svg" class="contain-img" alt="">
        </div>
        <div class="info">
          <div class="title">地址管理</div>
          <div class="desc">已添加地址信息管理</div>
        </div>
      </router-link>
    </div>

    <!-- 我的工单 -->
    <div class="card-box">
      <div class="card-header flex-between">
        <div class="title">我的工单</div>
        <router-link to="/order/submit" class="action">
          <span>前往</span>
          <van-icon name="arrow" size="9" color="#B7B7B7"/>
        </router-link>
      </div>
      <div class="card-box-main-container">
        <div class="card-scroll-box">
          <div class="card-box-main">
            <router-link to="/order/submit" class="card">
              <div class="icon">
                <img src="~@/assets/images/user/order_icon_1.png" class="contain-img" alt="">
              </div>
              <div class="label">已提交</div>
            </router-link>
            <router-link to="/order/dispatch" class="card">
              <div class="icon">
                <img src="~@/assets/images/user/order_icon_2.svg" class="contain-img" alt="">
              </div>
              <div class="label">已派工</div>
            </router-link>
            <router-link to="/order/inservice" class="card">
              <div class="icon">
                <img src="~@/assets/images/user/order_icon_3.svg" class="contain-img" alt="">
              </div>
              <div class="label">服务中</div>
            </router-link>
            <router-link to="/order/complete" class="card">
              <div class="icon">
                <img src="~@/assets/images/user/order_icon_4.png" class="contain-img" alt="">
              </div>
              <div class="label">已完成</div>
            </router-link>
            <router-link to="/order/cancel" class="card">
              <div class="icon">
                <img src="~@/assets/images/user/order_icon_6.png" class="contain-img" alt="">
              </div>
              <div class="label">已取消</div>
            </router-link>
          </div>
        </div>
        <div class="card-right-box">
          <div class="card-box-main">
            <router-link to="/logistics" class="card">
              <div class="icon">
                <img src="~@/assets/images/user/order_icon_5.svg" class="contain-img" alt="">
              </div>
              <div class="label">物流进度</div>
            </router-link>
          </div>
          <div class="line-bg">
            <img src="~@/assets/images/user/line_bg.png" alt="">
          </div>
        </div>
        
      </div>
      
    </div>

    <!-- 我的服务 -->
    <div class="card-box">
      <div class="card-header">
        <div class="title">我的服务</div>
      </div>
      <div class="card-box-main">
        <router-link to="/service" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_1.svg" class="contain-img" alt="">
          </div>
          <div class="label">在线报修</div>
        </router-link>
        <router-link to="/guarantee" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_2.svg" class="contain-img" alt="">
          </div>
          <div class="label">服务保修</div>
        </router-link>
        <router-link to="/charging" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_3.svg" class="contain-img" alt="">
          </div>
          <div class="label">收费标准</div>
        </router-link>
        <div class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_4.svg" class="contain-img" alt="">
          </div>
          <div class="label">产品指南</div>
          <div class="box" id="launch1"></div>
        </div>
        <a href="http://vip.wx.robam.com/#/home" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_5.svg" class="contain-img" alt="">
          </div>
          <div class="label">会员中心</div>
          <div class="box" id="launch3"></div>
        </a>
        <div class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_6.svg" class="contain-img" alt="">
          </div>
          <div class="label">服务商城</div>
          <div class="box" id="launch2"></div>
        </div>
        <router-link to="/feedback" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_9.svg" class="contain-img" alt="">
          </div>
          <div class="label">意见反馈</div>
          <div class="box" id="launch2"></div>
        </router-link>
        <a href="javascript:;" @click="closeWindow" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_7.svg" class="contain-img" alt="">
          </div>
          <div class="label">在线客服</div>
        </a>
        <a href="tel:9510-5855" class="card service-card">
          <div class="icon">
            <img src="~@/assets/images/user/service_icon_8.svg" class="contain-img" alt="">
          </div>
          <div class="label">服务热线</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { Icon, Toast, Dialog } from 'vant'
  import { sendServiceMsg } from '@/api/common'
  import { getUserInfo, getUserScore } from '@/api/user'
  import wx from 'weixin-js-sdk'
  // import img1 from '@/assets/images/user/service_icon_4.svg'
  // import img2 from '@/assets/images/user/service_icon_6.svg'

  
  export default {
    components: {
      [Icon.name]: Icon
    },
    data() {
      return {
        userinfo: {},
        html1: '',
        score: 0,
        level: ''
      }
    },
    created() {
      this.loadUserInfo()
    },
    mounted() {
      this.initBtn1()
      this.initBtn2()
      this.initBtn3()
    },
    methods: {
      initBtn1() {
        let script = document.createElement('script')  
        script.type = 'text/wxtag-template' 
        script.text = `<div style="padding:300px 300px;opacity:0;">0</div>`
        let html = `<wx-open-launch-weapp username="gh_6f90c5303966" >${script.outerHTML}</wx-open-launch-weapp>`
        let id = "launch1" 
        document.getElementById(id).innerHTML = html;
      },
      initBtn2() {
        let script = document.createElement('script')  
        script.type = 'text/wxtag-template' 
        script.text = `<div style="padding:300px 300px;opacity:0;">0</div>`
        let html = `<wx-open-launch-weapp username="gh_06d2df2c161a" >${script.outerHTML}</wx-open-launch-weapp>`
        let id = "launch2" 
        document.getElementById(id).innerHTML = html;
      },
      initBtn3() {
        let script = document.createElement('script')  
        script.type = 'text/wxtag-template' 
        script.text = `<div style="padding:300px 300px;opacity:0;">0</div>`
        let html = `<wx-open-launch-weapp username="gh_06d2df2c161a" >${script.outerHTML}</wx-open-launch-weapp>`
        let id = "launch3" 
        document.getElementById(id).innerHTML = html;
      },
      loadUserInfo() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getUserInfo().then(res => {
          this.userinfo = res.data.info
          this.score = res.data.info.score
          this.level = res.data.info.level
          toast.clear()
        })
      },
      reload() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getUserScore().then(res => {
          this.score = res.data.score
          this.level = res.data.level
          toast.clear()
        })
      },
      closeWindow() {
        Dialog.confirm({
          message: '是否前往咨询跳转老板服务公众号在线客服',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
          })
          sendServiceMsg().then(() => {
            wx.closeWindow()
            // this.addServer()
            toast.clear()
          })
        })
        .catch(() => {
          // on cancel
        });
      },
      addServer() {
        document.addEventListener('WeixinJSBridgeReady', function(){ 
          window.WeixinJSBridge.call('closeWindow'); 
        }, false);
        window.WeixinJSBridge.call('closeWindow');
      }
    },
  }
</script>
<style lang="less" scoped>
  @import './style.less';
</style>